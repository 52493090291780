// 
// _buttons.scss
// 

button,
a {
    outline: none !important;
}

//
// Soft Buttons
// 

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
    }

    &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
    }
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

// Soft Light button
.btn-soft-light {
    color: $gray-600;

    &:hover,
    &:focus,
    &:active {
        color: $gray-800;
    }
}

// Soft Light button
.btn-outline-light {
    color: $dark;

    &:hover,
    &:focus,
    &:active {
        color: $dark;
    }
}

//
// Split Dropdown buttons
// 

@mixin button-variant-split($bg) {
    background-color: lighten($bg, 5%);
    border: none;
}

@each $color,
$value in $theme-colors {
    .btn-#{$color}.dropdown-toggle-split {
        @include button-variant-split($value);
    }
}

.btn-light.dropdown-toggle-split {
    background-color: darken($light, 3%);
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}


// example 1

.btn-group-example {
    .btn {
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }
        
        &:active,
        &.show,
        &:focus{
            border: none !important;
        }

        &:before {
            content: "OR";
            position: absolute;
            font-size: 10px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            border-radius: 50%;
            background-color: $gray-600;
            color: $light;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}


// Example 2

.btn-label {
    position: relative;
    padding-left: 44px;
    border: none;

    .label-icon {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.15);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-light{
        .label-icon {
            background-color: rgba($dark, 0.1);
        }
    }

    &.btn-soft-light {
        .label-icon {
            background-color: rgba($dark, 0.05);
        }
    }
}


body[data-bs-theme="dark"] {

    .btn{
        color: $white;

        &:hover,
        &:active,
        &:focus{
            color: $white;
        }
    }
    // buttons 
    .btn-light {
        color: $gray-300;
        background-color: #0b334b;
        border-color: #0b334b !important;
    }
}